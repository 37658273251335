var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        { attrs: { id: "select-stage" } },
        [
          _vm._m(0),
          _c("stage-cards", {
            attrs: {
              cards: _vm.cards,
              "client-interaction-log": _vm.clientInteractionLog,
              "include-other-options": true,
            },
            on: { "navigate-to-hire-us": _vm.navigateToHireUs },
          }),
        ],
        1
      )
    : _c("ct-centered-spinner", { staticClass: "mt-5" }, [
        _vm._v("\n  Loading Stages...\n"),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-12 mb-3" }, [
      _c("h1", { staticClass: "header-text" }, [
        _vm._v("\n      How can we help?\n    "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }