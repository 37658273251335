<template>
  <div v-if="loaded" id="select-stage">
    <div class="col-md-12 mb-3">
      <h1 class="header-text">
        How can we help?
      </h1>
    </div>
    <stage-cards
      :cards="cards"
      :client-interaction-log="clientInteractionLog"
      :include-other-options="true"
      @navigate-to-hire-us="navigateToHireUs"
    />
  </div>
  <ct-centered-spinner v-else class="mt-5">
    Loading Stages...
  </ct-centered-spinner>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import AcceptingPayments from '@images/illustrations/products/accepting_payments.png'
  import BankAccount from '@images/illustrations/products/bank.png'
  import BusinessIdea from '@images/illustrations/products/idea.png'
  import ChangeOfAgent from '@images/illustrations/products/change_of_agent.png'
  import FinalizeLegalDocuments from '@images/illustrations/products/agreement.png'
  import Formation from '@images/illustrations/products/document.png'
  import GetDba from '@images/illustrations/products/trade_name.png'
  import Irs from '@images/illustrations/products/tax_id.png'
  import RegisterACompany from '@images/illustrations/products/register_a_company.png'
  import Trademarks from '@images/illustrations/products/trademark.png'
  import OnlinePresence from '@images/illustrations/products/online_presence.png'

  import {
    completeInteraction,
    createOrFindClientInteractionLog,
  } from '@/common/modules/clientInteractionLog'

  export default {
    name: 'SelectStage',
    components: {
      CtCenteredSpinner:   () => import('@/components/shared/CtCenteredSpinner'),
      StageCards:          () => import('./StageCards.vue'),
    },
    data() {
      return {
        clientInteractionLog: null,
        stageRequiresAttention: false,
        loaded: true,
        cards: [
          {
            image: BusinessIdea,
            title: 'Your Business Idea and Plan',
            description: 'Turn your idea into a business starting with a Business Plan.',
            stage: 'business_idea_and_plan',
            requiresAttention: false,
          },
          {
            image: Formation,
            title: 'Business Formation',
            description: 'All the forms and services you need to create a legal entity with the state.',
            stage: 'form_the_company',
            requiresAttention: false,
          },
          {
            image: Irs,
            title: 'EIN',
            description: 'Secure your EIN so you can open a bank account, start hiring employees, and more.',
            stage: 'irs',
            requiresAttention: false,
          },
          {
            image: FinalizeLegalDocuments,
            title: 'Company Documents',
            description: 'Operating Agreements and Bylaws will help lay the foundation of your business.',
            stage: 'finalize_legal_documents',
            requiresAttention: false,
          },
          {
            image: BankAccount,
            title: 'Open Bank Account',
            description: 'Separate personal and business finances, and establish a financial presence.',
            stage: 'bank_account',
            requiresAttention: false,
          },
          {
            image: ChangeOfAgent,
            title: 'Change your Agent',
            description: 'Save time and let us handle your paperwork quickly, with our 100% satisfaction guarantee.',
            hireUsProduct: 'Change Registered Agent',
            requiresAttention: false,
          },
          {
            image: RegisterACompany,
            title: 'Register a Company',
            description: 'Doing business in another state? We\'ll get you registered, quickly and correctly.',
            hireUsProduct: 'Register a Company',
            requiresAttention: false,
          },
          {
            image: Trademarks,
            title: 'Trademarks',
            description: 'Protect your brand name or design, and start building your reputation today.',
            stage: 'trademarks',
            requiresAttention: false,
          },
          {
            image: GetDba,
            title: 'Doing Business As (DBA)',
            description: 'Do business by another name when you sell a new product, expand territories, or re-brand.',
            stage: 'get_dba',
            requiresAttention: false,
          },
          {
            image: AcceptingPayments,
            title: 'Accepting Payments',
            description: 'Start accepting credit cards, get paid fast, and save money on payment processing.',
            stage: 'customer_payments',
            requiresAttention: false,
          },
          {
            image: OnlinePresence,
            title: 'Online Presence',
            description: 'Domains, websites, and emails—everything you need to get your business online.',
            stage: 'online_presence',
            requiresAttention: false,
          },
        ],
      }
    },
    computed: {
      ...mapGetters('stageline', [
        'company',
        'startEntityTypeSupported',
        'stageHasIncompleteConfigurationSlides',
        'periodDeepLoaded',
        'currentPeriodName',
        'ghostMode',
      ]),
      ...mapGetters('companies', [
        'formedElsewhere',
        'alreadyFormed',
      ]),
      showFormationStage() {
        return !this.formedElsewhere && !this.alreadyFormed
      },
    },
    async created() {
      this.loaded = false
      // TODO tried 'start'

      if (!this.periodDeepLoaded('start')) {
        await this.loadPeriod('start')
      }

      if (!this.showFormationStage) {
        this.cards = this.cards.filter(card => card.stage !== 'form_the_company')
      }
      this.loaded = true
    },
    async mounted() {
      this.checkForActionRequiredStages()
      await this.createClientInteraction()
    },
    methods: {
      ...mapActions('stageline', [
        'loadPeriod',
      ]),
      async navigateToHireUs() {
        await completeInteraction({
          id: this.clientInteractionLog.id,
          interaction: { type: 'button', action: 'redirect', name: 'hires_us' },
          completed: true,
        })

        await this.$router.push({
          name: 'choose-category',
          params: { companyId: this.company.id },
        })
      },
      filterCards(cardsToUse) {
        this.cards = this.cards.filter(card => {
          return cardsToUse.includes(card.stage)
        })
      },
      async createClientInteraction() {
        if (this.ghostMode) return

        try {
          const interaction = {
            action: 'view',
            name: 'view-select-stage',
            stage_requires_attention: this.stageRequiresAttention,
          }
          this.clientInteractionLog = await createOrFindClientInteractionLog({
            category: 'stageline',
            subCategory: 'select-stage',
            interaction: interaction,
            companyId: this.company.id,
            incompleteLogFromToday: true,
          })
        } catch (error) {
          // Just suppressing the error
          this.clientInteractionLog = null
        }
      },
      checkForActionRequiredStages() {
        this.cards.forEach(card => {
          card.requiresAttention = this.checkStageRequiresAttention(card.stage)
          this.stageRequiresAttention = this.cards.some(card => card.requiresAttention)
        })
      },
      checkStageRequiresAttention(stageName) {
        if (stageName !== 'online_presence') return false
        return this.stageHasIncompleteConfigurationSlides(stageName)
      },
    },
  }
</script>

<style lang="scss" scoped>
  #select-stage {
    display: flex;
    align-items: center;
    flex-direction: column;

    .header-text {
      font-size: 2.25rem;
      font-weight: 700;
      letter-spacing: -0.02em;
      text-align: center;
      margin-bottom: 0;
    }
  }
</style>
